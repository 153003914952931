import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Categories from "../../shared/categories/categories"

const BlogCategories = ({ hideOnMobile, hideOnDesktop }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityBlogCategory {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      allSanityBlogPost {
        group(field: tabs___content___category___id) {
          totalCount
          fieldValue
        }
      }
    }
  `)

  const categories = data.allSanityBlogCategory
  const totals = data.allSanityBlogPost.group

  return (
    <Categories
      categories={categories}
      totals={totals}
      hideOnMobile={hideOnMobile}
      hideOnDesktop={hideOnDesktop}
      allLink="/blog"
      name="blog posts"
    />
  )
}

export default BlogCategories
