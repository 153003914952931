import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SidebarForm from "../../shared/sidebarForm/sidebarForm"

const BlogSubscribeForm = ({ hideOnMobile, hideOnDesktop }) => {
  const data = useStaticQuery(graphql`
    {
      sanityBlogListing {
        tabs {
          content {
            hero
            subtitle
            subscribeForm {
              link
              title
            }
          }
        }
      }
    }
  `)

  const { link, title } = data.sanityBlogListing.tabs.content.subscribeForm

  return <SidebarForm link={link} title={title} hideOnDesktop={hideOnDesktop} hideOnMobile={hideOnMobile} />
}

export default BlogSubscribeForm
